.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
  }
  
  .google-map {
    width: 100%;
    height:100px;
  }
  
  .pin {
    display: flex;
    align-items: center;
    width: 18px;
    color: var(--main-blue);
  }
  
  .pin-icon {
    font-size: 4rem;
    color: black;
  }
  
  .pin-text {
    font-size: 1.3em;
    color: black;
  }
  
  @media screen and (min-width: 799px) {
    .google-map {
      height: 300px;
    }
  
    .map-h2 {
      font-size: 1.3rem;
      font-weight: 400;
    }
  
    .pin {
      width: 18vw;
    }
  
    .pin-icon {
      font-size: 30px;
    }
}